<template>
    <div class="p-grid p-fluid dashboard" style="padding: 10px;background: white">
      <div class="card card-w-title" id="divCard" style="padding:0px;min-width: 350px;width: 100%; overflow-x: auto;">
        <div class="p-mt-3">
          <DataTable :value="files" responsiveLayout="scroll">
            <Column field="name_file" header="Nombre de Documento"></Column>
            <Column field="created_at" header="Fecha creación"></Column>
            <Column header="Acciones">
              <template #body="slotProps">
                <Button icon="pi pi-download" :title="slotProps.data.name_file"
                        class="p-button-rounded p-button-info p-button-outlined p-mr-2"
                        @click="viewFile(slotProps.data.id)"/>
              </template>
            </Column>
          </DataTable>
          <div>
            <span v-if="files.length === 0">No existen documentos asociados a la matrícula.</span>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
import documentService from "../../service/document.service";

export default {
  name: "DocEnrollments",
  props: {
    enroll: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      files: [],
      documentId: 0,
    }
  },
  methods: {
    viewFile(id) {
      documentService
          .view(id)
          .then(async response => {
            const linkView = document.createElement('a');
            linkView.target = "_blank";
            linkView.href = response.data;
            linkView.click();
          })
          .catch((err) => {
            const message = err.response.data;
            let error = this.$utils.formatError(message);
            this.$toast.add({severity: 'error', summary: 'Error', detail: error, life: this.$utils.toastLifeTime()});
          });
    },
    getDocuments() {
      documentService
          .files(this.documentId)
          .then((response) => {
            this.files = response.data
          })
          .catch((err) => {
            const message = err.response.data;
            let error = this.$utils.formatError(message);
            this.$toast.add({severity: 'error', summary: 'Error', detail: error, life: this.$utils.toastLifeTime()});
          })
    },
  },
  mounted() {
    console.log(this.enroll);
    this.documentId = this.enroll;
      this.getDocuments();
  }
}
</script>

<style scoped>
.p-datatable.p-datatable-sm .p-datatable-tbody > tr > td {
  text-align: center !important;
}

.p-datatable table {
  table-layout: auto;
}
</style>
