import httpService from '../../../helpers/http.service'

export default {
    upload(id, params) {
        return httpService.post(`enrollments/${id}/documents`, params)
    },
    files(id) {
        return httpService.get(`enrollments/${id}/documents`, )
    },
    delete(id) {
        return httpService.delete(`enrollments/documents/${id}`, )
    },
    view(id) {
        return httpService.get(`enrollments/documents/${id}/url`, )
    },
}
